import { reduce } from 'lodash';
import { useState, useEffect } from 'react';
import querystring from 'querystring';

const UTMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const extractValidUtm = (params) => {
  return reduce(
    Object.keys(params),
    (acc, key) => {
      if (UTMS.indexOf(key) === -1) return acc;
      
      const value = params[key];
      if (!value || (Array.isArray(value) && (!value[0] || value[0] === ''))) {
        return acc;
      }

      return {
        ...acc,
        [key]: Array.isArray(value) ? value[0] : value
      };
    },
    {}
  );
};

const useUtm = (search = '') => {
  const [utm, setUtm] = useState({});

  useEffect(() => {
    const sessionUtm = window.sessionStorage.getItem('utm');
    const parsedSessionUtm = sessionUtm ? JSON.parse(sessionUtm) : null;

    if (parsedSessionUtm && Object.keys(parsedSessionUtm).length > 0) {
      const validUtm = extractValidUtm(parsedSessionUtm);
      
      if (Object.keys(validUtm).length > 0) {
        setUtm(validUtm);
      }
    } else if (search) {
      const searchParsed = querystring.parse(
        search.charAt(0) === '?' ? search.substring(1) : search
      );
      const utmQueryParams = extractValidUtm(searchParsed);

      if (Object.keys(utmQueryParams).length > 0) {
        sessionStorage.setItem('utm', JSON.stringify(utmQueryParams));
        setUtm(utmQueryParams);
      }
    }
  }, [search]);

  return utm;
};

export default useUtm;
